<template>
  <v-container id="category" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Category Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" v-bind="attrs" text @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Category Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" v-bind="attrs" text @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Category Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" v-bind="attrs" text @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="isFlashMessage" :timeout="4000" top>
      <span>{{ flashMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="isFlashMessage = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="categories"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Category' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Category</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="8" v-if="item.sub_category.length == 0">
          No Sub Categories
        </td>
        <td
          colspan="8"
          v-if="item.sub_category.length > 0"
          style="padding: 25px"
        >
          <v-row
            v-for="subCategory in item.sub_category"
            :key="subCategory._id"
          >
            <span style="padding-left: 10px">
              <b
                ><li>{{ subCategory.name }}</li></b
              >
            </span>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.store_name`]="{ item }">
        <div v-if="item.store_name[0] != null">
          {{ item.store_name[0].name }}
        </div>
      </template>
     
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Category',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Category',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteCategory(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Categories",
      modulePermission: [],
      expanded: [],
      singleExpand: false,
      search: "",
      categories: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      isFlashMessage: false,
      flashMessage: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Name", value: "name" },
        { sortable: false, text: "Margin", value: "margin" },
        { sortable: false, text: "Position", value: "position" },
        { sortable: false, text: "isTobacco", value: "is_tobacco" }
      ];
    },
  },
  methods: {
    getCategories() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "category/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.categories = response.data.categories.data;
            this.loading = false;
            this.totalPages = response.data.categories.last_page;
            this.startRecord = response.data.categories.from;
            this.endRecord = response.data.categories.to;
            this.totalRecord = response.data.categories.total;
            this.numbers = [];
            for (
              let num = response.data.categories.from;
              num <= response.data.categories.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getCategories();
    },
    deleteCategory(data) {
      if (data.sub_category != null && data.sub_category.length > 0) {
        this.flashMessage =
          "You can not delete category having sub categories!";
        this.isFlashMessage = true;
      } else {
        this.dialogDelete = true;
        this.deleteRecord = data._id;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "category/delete/" +
            this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getCategories();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getCategories();
    },
  },
  mounted() {
    this.getCategories();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>
